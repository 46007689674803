<template>
  <v-footer dark padless class="baloo_paaji_regular">
    <v-card class="flex" flat tile style="background: transparent">
      <v-card-text class="py-2 white--text text-center rodade_gradiente_dark">
        <span>Copyrigth &ensp; &copy;{{ new Date().getFullYear() }} —  <a href="http://www.prodatanet.com.br/" style="color: white; text-decoration: none"><b>Prodata Informática</b></a>. Todos os direitos reservados.</span>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script async>
/* eslint-disable */
import miscMixin from '../helpers/misc'
  export default {
    name: 'Rodape',
    mixins: [miscMixin],
    data: () => ({
      icons: [
        'mdi-youtube',
        'mdi-email',
        'mdi-facebook',
        'mdi-instagram',
      ],
    }),
    methods: {
    },
    components: {
      SiteSeguro: () => import('./SiteSeguro/SiteSeguro.vue')
    },
    computed: {},
    created() {
    },
    mounted() {
    },
    watch: {}
  }
</script>

<style scoped>

</style>
